export default [
  {
    name: 'Entities',
    iconUrl: '/icons/mdm.svg',
    linkPath: '/entities',
  },
  {
    name: 'Duplicate Records',
    iconUrl: '/icons/duplicates.svg',
    linkPath: '/duplicates',
  },
  {
    name: 'Quarantine',
    iconUrl: '/icons/quarantine.svg',
    linkPath: '/quarantine',
    permissions: [
      'read_quarantine',
    ],
  },
  {
    name: 'External Data',
    iconUrl: '/icons/harmonizations.svg',
    permissions: [
      'read_mapping-table',
      'read_data-source',
      'update_entity',
    ],
    subMenuItems: [
      {
        linkPath: '/datasources',
        name: 'Data Sources',
        permissions: [
          'read_data-source',
        ],
      },
      {
        linkPath: '/sync',
        name: 'Entity Sync',
        permissions: [
          'create_sync-configuration',
        ],
      },
      {
        linkPath: '/activations',
        name: 'Activations Sync',
        permissions: [
          'create_sync-configuration',
        ],
      },
      {
        linkPath: '/harmonization',
        name: 'Mapping Tables',
        permissions: [
          'read_mapping-table',
        ],
      },
    ],
  },
  {
    name: 'Reporting',
    iconUrl: '/icons/report-icon.svg',
    linkPath: '/reporting',
    permissions: [
      'read_reporting',
    ],
  },
];
