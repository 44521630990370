import entitiesRoutes from './entities-routes';
import quarantineRoutes from './quarantine-routes';
import harmonizationRoutes from './harmonization-routes';
import reportingRoutes from './reporting-routes';
import syncRoutes from './sync-routes';
import duplicatesRoutes from './duplicates-routes';

const routes = [].concat(
  [
    {
      path: '/callback',
      name: 'Callback',
      component: () => import('@/views/Auth/Callback'),
      meta: {
        menuItems: [],
        showBreadcrumb: false,
        auth: false,
        allowAnonymous: true,
      },
    },
    {
      path: '/',
      name: 'Home',
      redirect: { name: 'Entities' },
      meta: {
        allowNonOnboarded: false,
        breadcrumbTrail: [],
      },
    },
    {
      path: '/accessdenied',
      name: 'AccessDenied',
      component: () => import('@/views/AccessDenied'),
      meta: {
        menuItems: [],
        showBreadcrumb: false,
        auth: false,
        allowAnonymous: true,
        allowNonOnboarded: true,
      },
    },
    {
      path: '/error',
      name: 'Error',
      component: () => import('@/views/Home/Error'),
      meta: {
        auth: false,
        showBreadcrumb: false,
      },
    },
  ],
  reportingRoutes,
  syncRoutes,
  harmonizationRoutes,
  duplicatesRoutes,
  quarantineRoutes,
  entitiesRoutes,
);

export default routes;
